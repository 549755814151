import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Kulinarische extends React.Component {
   

  render() {
    return (
        <>
       <embed src="Speisekarte deutsch final.pdf#toolbar=0" width="100%" height="375" type="application/pdf">
</embed>		

        </>
        );
  }
}
export default Kulinarische; 