import React from 'react';  
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#d7ae2f',
  backgroundColor: '#112f8a',
  '&:hover': {
    backgroundColor: "#9f2f3c",
  },
}));

class IndexElement extends React.Component
{
  render()
  {
    return (
      <div class="continer-fluid p-3" >
       
        {/* <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'100%'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'80%',margin:'auto'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
        <img src='/curryHome.jpeg' style={{width:'50%',margin:'auto'}}/>
        </Box> */}
        

        <div class="jumbotron" style={{ 
          width: "100%",
          height: "400px",
          "background-size": "100% 100%",
        //  "background-position": "center",
     // backgroundImage: `url("./background.jpeg")` 
    }}>
		            <h2 class="jumbotron-description-container">
                <span class="jumbotron-description-left-dash ml-auto"></span>
                <span class="jumbotron-description lead">Spezialitäten aus Sri Lanka</span>
                <span class="jumbotron-description-right-dash mr-auto"></span>
            </h2>
            <h1 class="lead">Curryinsel</h1>
            {/* <p style={{"font-size": "1.25rem"}}>Derzeit nur Barzahlung möglich</p>
            <p style={{"font-size": "1.25rem"}}>Cash only</p> */}
            <Link to={"/reservierung"} style={{ textDecoration: 'none' }}><ColorButton variant="contained" color='secondary'>RESERVIERUNG</ColorButton></Link>         
            </div>


        
    </div>
    )
  }
}
export default IndexElement;