import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Brunch extends React.Component {
   

  render() {
    return (
        <>
       <header class="entry-header">
				<h1 class="entry-title page-title" > Brunch</h1>	</header>
        {/* <h4 class="   rt-heading" >Nähere Informationen erhalten Sie in Kürze hier!</h4> */}
       
       <h4> Samstag und Sonntag </h4>
       <h4>All You can Eat  </h4>
       <h4> 11 bis 15 Uhr </h4>
       <h4>23 Euro /Person</h4>
       <br></br>
        <h5>Wir nehmen Sie mit auf eine Reise durch die weniger bekannten Speisen Sri Lankas - freuen Sie sich auf eine Zusammenstellung neuer Geschmackserlebnisse! 

        </h5>
        <br></br>
        <h5>Wir ersuchen um Reservierung!</h5>
        {/* <p>
        <span style={{    'font-size': 'larger'}}><font  face="Roboto">Ab sofort bieten wir an Samstagen und Sonntagen unser Frühstück mit Sri Lanka Spezialitäten an!</font></span>
        
        
        </p>
        <p>
        Wählen Sie aus unserer Frühstückskarte ihr individuelles exotisches Frühstück, mit Köstlichkeiten, die gerne auf srilankischen Frühstückstischen landen. Wie üblich, werden die Speisen in kleinen Portionen serviert, so können Sie immer wieder Verschiedenes probieren!
        </p>
        <p>Auf Wunsch haben Sie auch die Möglichkeit, Sri Lanka Spezialitäten mit  Frühstücksklassikern hierzulande zu kombinieren (Gebäck, Butter, Marmelade, Eiervariationen…) Von Woche zu Woche gibt es immer wieder kleine Änderungen im Angebot – so bleibt es abwechslungsreich.</p>
        <p style={{'marginTop':'30px'}}>Ein Heißgetränk nach Wahl ist inkludiert!</p>
        <p style={{'marginTop':'30px'}}>Preis Euro 23,50 /Person</p>
        <p style={{'marginTop':'30px'}}>Wir ersuchen um Reservierung!</p> */}
       
        </>
        );
  }
}
export default Brunch; 